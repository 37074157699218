.fs-wrapper {
	width: 100%;
	position: relative;
	//*padding: 2rem;

	.fs-overlay {
		display: flex;
		z-index: 90;
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		width: 100%;
		background-color: #EEEEEE;
		align-items: center;
		justify-content: center;
		opacity: 0.6;

		i {
			font-size: 3rem;
		}
	}

	.fs-breadcrumbs {
		font-size: 15px;
		width: auto;

		&:hover {
			text-decoration: underline;
			cursor: pointer;
		}

		&:last-child {
			i {
				display: none;
			}
		}
	}

	.fs-actions-btn {
		font-size: 15px;
		border: none;

	}

	.fs-icon {
		font-size: 70px;
	}

	.fs-box {
		cursor: pointer !important;

		.fs-back-btn {
			width: 120px;
		}

		p {
			overflow-wrap: anywhere;
		}

		&.fs-box-image {
			background-image: url("https://pim.arredo3.it/_default_upload_bucket/loader.gif");
			background-position: center center;
			background-repeat: no-repeat;
			background-size: 64px 64px;
		}

		&:hover {
			background-color: #dee2e6 !important;
		}
	}

	.fs-content {
		background-color: white;
		border-radius: 0.375rem;

		.fs-grid-mode {
			padding: 2rem;


			.fs-image {
				width: 120px;
				height: auto;
			}
		}

		.fs-list-mode {
			padding-left: 15px;
			padding-right: 15px;

			.fs-icon-back, .fs-icon {
				font-size: 20px;
			}

			.fs-list-header {
				padding-top: 7.5px;
				padding-bottom: 7.5px;
				border-bottom: 0.5px solid #EEEEEE;
				border-radius: 0.375rem;
				font-size: 16px;
			}

			.fs-list-row {
				padding-top: 7.5px;
				padding-bottom: 7.5px;
				font-size: 14px;
				cursor: pointer;

				&:hover {
					background-color: #F3F0F0FF;
				}
			}
		}

		.icon-yellow {
			color: #E89B0BFF;
		}

		.icon-blue {
			color: #2929FCFF;
		}

		.icon-red {
			color: #C93B3BFF;
		}
	}
}

.fs-manuals {
	width: 100%;
	position: relative;

	.fs-overlay {
		display: flex;
		z-index: 90;
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		width: 100%;
		background-color: #EEEEEE;
		align-items: center;
		justify-content: center;
		opacity: 0.6;

		i {
			font-size: 3rem;
		}
	}

	.fs-gallery {
		.fs-img {
			height: 25vh;
			background-repeat: no-repeat;
			background-size: cover;
			background-position: center center;
		}

		.fs-img:hover {
			background-color: #000000;
			opacity: 0.5;
		}
	}
}

@media screen and (max-width: 992px) {
	.fs-wrapper {
		.fs-content {
			.fs-grid-mode {
				.fs-box {
					padding: 1.5rem;

					.fs-box-content {
						.fs-box-label {
							font-size: 14px;
							text-align: center;
						}
					}

					.fs-icon {
						font-size: 50px;
					}
				}
			}

			.fs-list-mode {
				.fs-list-header {
					font-size: 12px;
				}

				.fs-list-row {
					font-size: 12px;
				}
			}
		}
	}
}