.t300 {
  font-weight: 300;
}

.t400 {
  font-weight: 400;
}

.t700 {
  font-weight: 700;
}

.title1 {
  font-size: $font5;
  font-weight: 300;
}

.title2 {
  font-size: 3rem;
  font-weight: 300;
}


.font-reset {
  font-size: 1.25rem !important;

  p, a, span, b, font, div {
    font-size: 1.25rem !important;
  }
}

