/** System banners**/

.ts_development_banner {
  position: relative;
  cursor: pointer;

  &:hover {
    .ts_development_banner_info {
      display: block;
    }
  }
}

.ts_development_banner_info {
  display: none;
  position: absolute;
  background-color: red;
  opacity: .7;
  color: white;
  padding: .5rem 1rem;
  top: 0;
  right: 0;
  z-index: 10;
  font-size: 1rem;
  min-width: $sidebarWidth;
}

.pre{
  padding: 1rem;
  font-size: 1.3rem;
  background-color: white;
  margin-bottom: 1rem;
}


pre{
  padding: 0 1.5rem;
  font-size: 1.3rem;
  background-color: white;
  margin-bottom: 1rem;
}

.dev_subtitle{
  font-size: $font2;
  margin-bottom: $m3;
  font-weight: 700;
}

.dev_text{
  font-size: $font2;
  margin-bottom: $m3;
}