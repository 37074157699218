/* General */
$appSize: 10px;
$appFont: 'D-DIN', 'D-DIN Bold';
$appStandalonePagesFont: 'D-DIN', 'D-DIN Bold', sans-serif;

/* Layout settings */
$notificationWidth: 36rem;
$sidebarWidth: 30rem;
$contextualWidth: 20rem;
$detailWidth: 37rem;
$navbar-height: 9vh;
$navbar-height-mobile: 9vh;

/* UX elements */
$radius_small: .4rem;
$radius_medium: .6rem;
$radius_large: 1.2rem;
$speed_fast: .3s;
$speed_medium: .6s;
$speed_slow: 1s;

/* Colors */
$color_greyscale_white: #EEEEEE;
$color_greyscale_lighter: #f7f7f7;
$color_greyscale_light: #EEEEEE;
$color_greyscale_medium: #CCCCCC;
$color_greyscale_dark: #2A2A2A;
$color_greyscale_darker: #1A1A1A;
$color_greyscale_darker: #000000;
$color_greyscale_black: #1A1A1A;
$gray-800: #2A2A2A !default;
$color_active: #035637;
$color_theme1: #E6B35B;
$color_theme2: #8E9C8E;
$color_theme3: #4E8D6E;
$color_theme4: #BCBC90;
$color_theme5: #71A3B8;
$color_theme6: #89896F;
$color_theme7: #D5D596;
$color_primary_lighter: #E0E0E0;
$color_primary_light: #B6B6B6;
$color_primary_medium: #626262;
$color_secondary_lighter: #4E8D6E;
$color_secondary_light: #4E8D6E;
$color_secondary_medium: #4E8D6E;
$color_accent_medium: red;
$color_error_medium: red;
$color_warning_medium: #e63900;
$color_success_medium: green;

$_accent_white: #fff;
$_accent_dark_green: #0e6339;
$_accent_red: #d12d34;
$_accent_black: #000000;

/* Layout size */
$m1: .5rem;
$m2: 1rem;
$m3: 1.3rem;
$m4: 1.6rem;
$m5: 3rem;
$m6: 4rem;
$m7: 6rem;
$m8: 8rem;
$m9: 11rem;
$m10: 20rem;
$m11: 2rem;
$modules: $m1 $m2 $m3 $m4 $m5 $m6 $m7 $m8 $m9 $m11;

/* Font size, font weights */
$font0: 1rem;
$font1: 1.2rem;
$font2: 1.5rem;
$font3: 2.1rem;
$font4: 2.6rem;
$font5: 4rem;
$fontsizes: $font0 $font1 $font2 $font3 $font4 $font5;
$fontweights: 300,400,600,700;
