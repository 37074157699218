html {
  font-size: $appSize;
}

body {
  font-family: $appFont;
}

.table-reset {
  th, td {
    font-size: 1.25rem !important;
    vertical-align: middle !important;
  }
}

.dx-datagrid-headers {
  .dx-datagrid-table {
    .dx-row {
      td {
        vertical-align: middle;
        display: table-cell;

        .dx-datagrid-text-content {
          vertical-align: middle;
          display: table-cell;
          line-height: 1;
        }

        .dx-column-indicators {
          vertical-align: middle;
          display: table-cell;
          margin-left: 3px;
        }
      }
    }
  }
}

.dx-datagrid-rowsview {
  .dx-datagrid-table {
    .dx-row {
      td {
        vertical-align: middle;
      }

      &:hover {
        td, .grid_action1 {
          background: #ccc;
        }
      }
    }
  }
}

.ts_devexpress_table .dx-header-row td:not(:first-of-type):hover:after {
  content: '' !important;
}

.dx-datagrid-filter-row .dx-menu {
  margin-top: 0;
}

.dx-datagrid-filter-row {
  .dx-menu-item-has-submenu {
    background-color: transparent;

    &.dx-menu-item-expanded {
      background-color: transparent;

      &.dx-state-hover {
        background-color: transparent;
      }
    }

    &.dx-state-hover {
      background-color: transparent;
    }
  }
}

.dx-context-menu {
  .dx-context-menu-content-delimiter {
    background-color: transparent;
  }
}

.dx-context-menu-container-border {
  background-color: transparent;
  border: none;
  box-shadow: none;
}

.logo-mobile {
  max-width: 46px !important;
  height: auto;
}

.mobile-app-nav {
  z-index: 9999999;
}

.full-link {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.contentBlockWrapper {
  display: none !important;
}

.table {
  th, td {
    vertical-align: middle;
  }
}

.multiselect {
  z-index: 9999 !important;
}

.userImpersonationLink {
  &:hover {
    background: #eeeeee;
  }
}

input {
  outline: none;
}

input.pin-control {
  height: 45px;
  border: 2px solid #333333;
  font-size: 25px;
  border-radius: 10px;
  text-align: center;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}