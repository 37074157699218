/// Set color based on background
/// @bgcolor MUST BE the color used on background where text is placed
/// @limit is the limit to change color from black to white based on lightness
@function set-text-color($bgcolor, $limit: 50) {
  @if (lightness($bgcolor) > $limit) {
    @return $color_greyscale_darker;
  }
  @else {
    @return $color_greyscale_lighter;
  }
}

// Placeholders
%buttons_wrapper {
  span {
    display: inline-block;
    margin: 2rem;
    font-size: 1.5rem;
  }
  .outlined_cta {
    width: 220px;
    text-transform: capitalize;
    &.accept {
      color: $_accent_white;
      background-color: $_accent_dark_green;
      &:hover {
        background-color: lighten($_accent_dark_green, 10%);
      }
    }
    &.reject {
      color: $_accent_white;
      background-color: $_accent_red;
      &:hover {
        background-color: lighten($_accent_red, 10%);
      }
    }
  }
}

%outlined_buttons {
  .outlined {
    &_card {
      width: 100%;
      padding: 4rem;
      background-color: #f0f0f0;
    }

    &_cta {
      appearance: none;
      font-size: 2.4rem;
      letter-spacing: 0.1rem;
      text-align: center;
      height: 65px;
      width: 300px;
      max-width: 100%;
      color: $_accent_white;
      background-color: #000;
      border: 0;

      &[disabled] {
        opacity: .2;
      }

      &:not([disabled]):hover {
        cursor: pointer;
        background-color: lighten(#000, 30%);
      }
    }
  }
}

@mixin clearfix {
  &::after {
    content: "";
    display: table;
    clear: both;
  }
}
