/** Notifications **/

.ts_notifications {
  position: fixed;
  top: 4rem;
  right: 2rem;
  z-index: 300;
}

.ts_notification_item {
  color: $color_greyscale_lighter;
  margin-top: .5rem;
  font-size: $font1;
  width: $notificationWidth;
  font-weight: 700;
  opacity: .9;

  &.success {
    background-color: $color_success_medium;
    border: solid 1px $color_success_medium;
    padding: 1.5rem 2rem;
  }

  &.regular {
    background-color: $color_greyscale_darker;
    border: solid 1px $color_greyscale_darker;
    padding: 1.5rem 2rem;
  }

  &.warning {
    background-color: $color_warning_medium;
    border: solid 1px $color_warning_medium;
    padding: 1.5rem 2rem;
  }

  &.error {
    background-color: $color_error_medium;
    border: solid 1px $color_error_medium;
    padding: 1.5rem 2rem;
  }

}