.ts_info_title {
	background-color: white;
	color: $color_greyscale_dark;
	border-color: white;
	transition: $speed_fast;
	display: flex;
	border-width: 1px;
	border-style: solid;
	padding: $m2;
	min-height: $m4;
	font-size: $font2;
	text-align: left;
	text-transform: uppercase;
	font-weight: 400;
	cursor: pointer;
	align-items: center;
	cursor: text;
	margin-bottom: $m4;

	i {
		margin-right: 1rem;
	}

	h2 {
		font-size: $font2;
	}

}

.section_item {
	border-bottom: solid 1px $color_greyscale_dark;
	padding: 0 1rem 1rem 1rem;
	margin-bottom: $m2;
	color: $color_greyscale_dark;

	label {
		font-size: $font1;
	}

	p {
		font-size: $font2;
		font-weight: 700;
	}
}


.ts_info_item {
	border-bottom: solid 1px $color_greyscale_light;
	padding: 0 1rem 1rem 1rem;
	margin-bottom: 1rem;
}

.style_list_label {
	font-size: $font2;
	color: $color_greyscale_darker;
}

.style_list_label2 {
	font-size: 1.8rem;
	color: $color_greyscale_black;
}

.form-input {
	height: calc(2.5rem + 0.75rem + 2px);
	font-size: 1.8rem;
	border: solid 2px #2A2A2A;
}

.form-group {
	.style_list_label_form {
		font-size: 2rem;
		color: $color_greyscale_darker;
	}

	.form-control {
		font-size: 2rem;
	}
}

.style_list_content {
	font-size: 1.3rem;
	font-weight: 700;
	color: $color_greyscale_darker;
}

.style_list_content a {
	color: $color_theme3;
	text-decoration: underline;
}


.ts_info_block {

	background-color: $color_greyscale_lighter;
	padding: 2rem 2rem;
	cursor: pointer;
	color: $color_greyscale_darker;
	transition: opacity $speed_fast;
	position: relative;

	h2, p {
		color: $color_greyscale_darker;
	}

	.ts_info_block_options {
		position: absolute;
		right: 1rem;
		top: 1rem;

		i:before {
			color: $color_greyscale_dark;
			transition: $speed_fast;
		}

		i:hover:before {
			color: $color_greyscale_darker;
		}

	}


	.ts_info_block_icon {
		width: 5rem;
		height: 5rem;
		text-align: center;
		display: inline-flex;
		justify-content: center;
		align-items: center;
		color: white;
		font-size: $font3;
		font-weight: 400;
	}

}

.ts_info_link {
	cursor: pointer;
}

.ts_info_title {
	background-color: $color_greyscale_lighter;
	padding: 1rem 1rem;
	display: flex;
	align-items: center;

	&.ts_info_title_alert {
		background-color: $color_error_medium;

		h2, i:before {
			color: white;
		}
	}

	&.ts_info_title_warning {
		background-color: $color_secondary_light;

		h2, i:before {
			color: $color_greyscale_darker;
		}
	}

	&.ts_info_title_success {
		background-color: $color_success_medium;

		h2, i:before {
			color: white;
		}

	}

	&.ts_info_title_dark {
		background-color: $color_greyscale_dark;

		h2, i:before {
			color: white;
		}
	}

	h2 {
		color: $color_greyscale_darker;
		margin-left: 1rem;
	}
}

.ts_info_item {
	border-bottom: solid 1px $color_greyscale_light;
	padding: 0 1rem 1rem 1rem;
	margin-bottom: 1rem;
}

.ts_info_item_fullheight {
	height: 100%;
	padding-bottom: 0;
}

.ts_info_preview {
	cursor: pointer;
}

.document_folder_main {
	.ts_info_title:first-of-type {
		margin-top: 0 !important;
	}
}

.reclamo_section {
	margin-bottom: $m7;

	&.inactive {
		pointer-events: none;
		opacity: .5;

		.reclamo_actions {
			display: none;
		}
	}
}

.reclamo_picture_add {

	text-align: center;

	p {
		font-size: $font1;
		margin-bottom: 0;
		margin-top: $m1;
		text-transform: uppercase;
	}

	.reclamo_picture_add_button {
		border: solid 1px white;
		height: 0;
		width: 100%;
		background-color: $color_greyscale_lighter;
		padding-bottom: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		position: relative;
		cursor: pointer;

		i {
			font-size: 4rem;
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);
			width: 5rem;
			height: 5rem;
			color: $color_greyscale_dark;
		}
	}

}

.reclamo_picture_loaded {

	text-align: center;

	/*p {
		font-size: $font1;
		margin-bottom: 0;
		margin-top: $m1;
		text-transform: uppercase;
		text-decoration: underline;
		cursor: pointer;
	}*/

	.reclamo_picture_loaded_img {
		border: solid 1px white;
		height: 0;
		width: 100%;
		background-color: $color_greyscale_lighter;
		padding-bottom: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		position: relative;
		overflow: hidden;

		img {
			position: absolute;
			left: 0;
			top: 0;
			object-fit: cover;
			width: 100%;
			height: 100%;
		}
	}
}

.file-loaded {
	border: solid 1px white;
	height: 0;
	width: 100%;
	background-color: $color_greyscale_lighter;
	padding-bottom: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
	overflow: hidden;

	img {
		position: absolute;
		left: 0;
		top: 0;
		object-fit: cover;
		width: 100%;
		height: 100%;
	}

	&:hover {
		opacity: 0.8;
	}
}