@font-face {
	font-family: "D-DIN";
	src: url("fonts/D-DIN.woff2") format("woff2"),
	url("fonts/D-DIN.woff") format("woff"),
	url("fonts/D-DIN.ttf") format("truetype");
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "D-DIN Bold";
	src: url("fonts/D-DIN-Bold.woff2") format("woff2"),
	url("fonts/D-DIN-Bold.woff") format("woff"),
	url("fonts/D-DIN-Bold.ttf") format("truetype");
	font-weight: bold;
	font-style: normal;
	font-display: swap;
}

strong{
	font-family: "D-DIN Bold";
	font-weight: normal !important;
}