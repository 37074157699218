.data_table_options_minified {
    //padding: 2rem 0rem;
    display: flex;
}

.dx-datagrid-headers .dx-datagrid-table .dx-row td .dx-datagrid-text-content {
    color: black;
}

.data_table_options_content {
    padding: 2rem;
    position: relative;
    font-size: $font1;

    // #selected-date1,
    // #selected-date2,
    // input[type="text"]{
    //   background-color: transparent!important;
    // }

    .dx-datebox, .dx-dropdownbox, .dx-selectbox, .dx-texteditor {
        //height: $m6 !important;
        //border: none;
        //border-bottom: solid 2px $color_greyscale_dark;
    }

    input[type="text"] {
        //height: $m6 !important;
        //min-height: $m6 !important;
        //padding-top: 4px;
        //padding-bottom: 4px;
    }

    input[type="text"].ts_input2 {
        //height: 2.9rem !important;
        //min-height: 2.9rem !important;
    }

    .dx-placeholder {
        //line-height: 1rem;
    }

    .dx-datebox input {
        //padding-top: 0 !important;
        //padding-bottom: 0 !important;
        //height: calc(#{$m6} - 4px) !important;
    }

    .dx-texteditor-input {
        //min-height: 0;
        padding: 0;
    }

    h2, h3, h4, h5 {
        //margin-top: 0 !important;
    }
}

.ts_data_table {
    position: relative;
    display: block;
    height: auto;
    overflow-y: hidden;
}

.ts_data_table_options_toggle {
    padding: 1rem;
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;

    i {
        font-size: 2rem;
    }
}

@media screen and (max-width: 992px) and (min-width: 768px) {
    .dx-selectbox {
        //width: 6rem;
    }
}

@media screen and (max-width: 767px) {
    .ts_button1,
    .ts_buttoninline1 {
        display: flex;
        justify-content: center;
        //margin: 0 $m2 $m2 $m2;
        margin-top: $m2;
    }

    .ts_buttoninline1 {
        border-color: $color_greyscale_dark;
        background-color: $color_greyscale_dark;
        color: white;
    }

    .data_table_options_minified {
        display: block;
        padding: 0 2rem 2rem 2rem;
    }

    .data_table_options_content {

        h2, h3, h4, h5 {
            margin-top: $m2 !important;
        }

    }

    .dx-selectbox {
        //width: 7rem;
    }

    .table-options {
        //margin-top: 1.6rem;
    }

    .ts_data_table_options {
        .header-btn {
            margin: 15px;
        }
    }
}