.ts_user_option {
    cursor: pointer;
    position: relative;
}

.mobile-hamburger{
    display: none;
}

.divider {
    height: 36px;
    width: 1px;
    background-color: #dee2e6;
}

.version {
    padding: 0 1rem 0 1rem;
    font-size: 1.2rem;
}

.options-inline {
    display: contents;
}

.toolbar-icons {
    font-size: 2rem;
}

.lang-switcher {
    width: 42px;


    .lang-selected {
        cursor: pointer;
        //border: none;
        background-color: white;
        text-align: center;

        button {
            font-weight: unset;
            font-size: 1.6rem;
        }

    }
    .lang-list {
        background-color: white;
        position: absolute;
        border-top: solid 1px $color_greyscale_black;
        display: grid;

        button, div {
            font-weight: unset;

            &:hover {
                font-weight: bold;
            }
        }
    }
}

.modal-lang-switcher {
    .single-lang {
        text-transform: uppercase;
        font-size: 2.5rem;
        padding: 1rem 0 1rem 0;
        cursor: pointer;

        &:hover {
            font-weight: bold;
        }

        .lang-flag-img {
            width: 30px;
            margin-right: 20px;
        }
    }
}

/* Tablet e smartphone */

@media screen and (max-width: 996px) {
    .mobile-hamburger{
        display: block;
        padding-right: 12px;
    }
}


/* Solo Tablet */

@media screen and (max-width: 992px) and (min-width: 768px) {

}

/* Smartphone */

@media screen and (max-width: 767px) {


}