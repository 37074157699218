
.ts_breadcrumb {

  font-size: $font2;


  p{
    margin-bottom: 0;
  }

  .ts_breadcrumb_inactive,
  .ts_breadcrumb_active {
    display: inline-flex;
    align-items: center;
    padding-right: 1.4rem;
    margin-bottom: 0;
    padding-top: 1rem;
    padding-bottom: 1rem;
    transition: $speed_fast;

    i{
      margin-left: 1.5rem;
    }

  }

  .ts_breadcrumb_active {
    cursor: pointer;
    opacity: .5;
  }

  .ts_breadcrumb_active:hover {
    opacity: 1;
  }



}
