/* Buttons */

.grid_action1 {
    border: none;
    padding: 1rem;
    background-color: transparent;
    cursor: pointer;
}

.style_button_mini_label {
    border: solid 1px transparent;
    padding: 0 .4rem 0 .4rem;
    height: 2.4rem;
    font-size: 1rem;
    text-align: left;
    color: white;
    text-transform: uppercase;
    font-weight: 400;
    cursor: pointer;
    letter-spacing: .05em;
    transition: $speed_fast;
    display: inline-flex;
    align-items: center;
    margin-right: .5rem;

    span {
        margin-left: .8rem
    }

    small {
        color: white;
        margin-left: 2px;
        font-size: .7rem;
    }
}

/*Overrides*/

.dx-texteditor-input {
    padding: 0;
}

.dx-texteditor.dx-editor-outlined {
    background: none;
    border: none;
    border-radius: 0;
}

.dx-datagrid-focus-overlay {
    border: none;
}

.ts_devexpress_table .dx-datagrid-rowsview .dx-row.dx-group-row {
    color: black;
}

.ts_devexpress_table .dx-datagrid-filter-row .dx-texteditor-container {
    //border: solid 2px $color_greyscale_dark !important;
}

.ts_devexpress_table .dx-datagrid-filter-row .dx-menu {
    padding-left: .25rem;
}

.dx-datagrid .dx-row.ts_cell_alert td, .dx-datagrid .dx-row-alt.ts_cell_alert td {
    background-color: $color_warning_medium;
    color: white;

    p, h2, a {
        color: white;
    }
}

.dx-datagrid-group-closed, .dx-datagrid-group-opened {
    margin: 0;
    position: absolute;
    top: 30%;
}

.ts_devexpress_table_mini {
    background-color: $color_greyscale_light;
    padding: 1rem;
}

.ts_devexpress_table table {
    font-size: $font1;
}

.tesy_tablefontsize_1 .ts_devexpress_table table {
    font-size: .8rem;
}

.tesy_tablefontsize_2 .ts_devexpress_table table {
    font-size: $font1;
}

.tesy_tablefontsize_3 .ts_devexpress_table table {
    font-size: $font2;
}

.tesy_tablefontsize_4 .ts_devexpress_table table {
    font-size: $font3;
}

.tesy_tablefontsize_5 .ts_devexpress_table table {
    font-size: $font4;
}

.ts_devexpress_table {

    .ts_grid_cell_center {
        display: flex;
        top: 0;
        bottom: 0;
        align-items: center;
        position: absolute;
    }

    .dx-datagrid-header-panel {
        display: none;
    }

    .dx-datagrid-pager {
        border-top: solid 1px $color_greyscale_light;
        padding-right: 1rem;
        font-size: $font1;
    }

    .dx-page {
        font-size: $font0;
    }

    .dx-datagrid-nowrap .dx-header-row > td > .dx-datagrid-text-content {
        font-size: $font0;
        font-weight: 700;
        color: $color_greyscale_dark;

        &.dx-sort-indicator {
            color: $color_greyscale_darker;
        }
    }

    .dx-datagrid-headers {
        //border-top: none;
        //border-left: none;
        //border-right: none;
    }

    .dx-datagrid-rowsview {
        //border-top: none;

        tr td {
            position: relative;
            vertical-align: middle;
        }
    }

    .dx-header-row td {
        //border: none !important;
        position: relative;
        overflow: visible;

        &:not(:first-of-type):hover:after {
            position: absolute;
            height: 2rem;
            display: block;
            top: 1rem;
            left: -1.5rem;
            width: 2rem;
            content: "\e259";
            transform: rotate(90deg);
            z-index: 1;
            font-size: 1rem;
            color: $color_greyscale_darker;
        }

        .dx-header-row td {
        }
    }

    .dx-datagrid-filter-row {
        //border: none;

        .dx-editor-with-menu {
            //margin: 0 0 2rem 0;
            background-color: #f8f8f8;
            height: 5rem;
            display: flex;
            align-items: center;
        }

        .dx-texteditor-input {
            //border: none;
            padding-top: .5rem !important;
            padding-bottom: .5rem !important;
            height: 3rem !important;
            min-height: 0;
        }
    }

    .dx-datagrid-content tr td:first-child {
        padding-left: 2rem;
    }

    .dx-datagrid-content tr td:last-child {
        padding-right: 2rem;
    }

    .dx-editor-cell:first-child {
        padding-left: 2rem !important;
    }

    .dx-editor-cell {
        padding-left: .7rem !important;
        padding-right: .7rem !important;
    }

    //altezza riga filtri tabella
    .dx-datagrid-filter-row {
        .dx-editor-cell {
            //height: 6rem;
        }
    }

    //altezza righe tabella
    .dx-row.dx-data-row.dx-row-lines {
        height: 5rem;
    }

    .dx-header-row .dx-select-checkbox {
        //transform: translateY(3rem);
    }

    .dx-master-detail-cell {
        .ts_gridoptions_wrapper {
            position: static;
        }
        .ts_cell_alert td {
            background-color: transparent;
            color: inherit;
        }
        .dx-adaptive-item-text {
            padding: 0 !important;
        }
    }
}

.dx-scrollable-container, .dx-scrollable-native.dx-scrollable-container {
    height: auto !important;
}

.ts_data_table_options {
    overflow-x: hidden;
    margin-top: 15px;
}

.dx-datagrid {
    background-color: transparent;
}

.dx-filter-menu.dx-menu .dx-menu-item .dx-menu-item-content {
    padding-top: 0px;
}

.dx-widget input, .dx-widget textarea {
    font-family: $appFont !important;
}


.ts_devexpress_table {
    .dx-datagrid-filter-row {
        background-color: #f8f8f8;
        .dx-texteditor-container {
            //border-bottom: solid 2px $color_greyscale_black !important;

            input {
                background-color: #f8f8f8;
            }
        }
    }
}

/* Tablet e smartphone */

@media screen and (max-width: 992px) {
    .dx-filter-menu.dx-menu .dx-menu-item .dx-menu-item-content {
        padding-top: 0px;
    }
}

@media screen and (max-width: 767px) {
    .grid_action1 {
        font-size: 3rem;
        padding: 0;
        margin-right: 3rem !important;
    }
}