/* Variabili e classi generate */
@import 'variables';
@import "~bootstrap/scss/bootstrap";

/*
  Funzioni
*/
@import "helpers";

/* Stili generali */
@import 'general';
@import 'layout';
@import 'animations';
@import 'forms';
@import 'sections';
@import 'type';
@import 'icons';
@import 'font';

/* Componenti */
@import 'header';
@import 'notifications';
@import 'modals';
@import 'tooltips';
@import 'sidebar';
@import 'breadcrumb';
@import 'dashboard';
@import 'tableoptions';
@import 'pagination';
@import 'grid';
@import 'development';
@import 'errors';
@import 'chart';
@import "filesystem";
@import "progress-bar";

/* Viste */
@import "standalone-page";

/* Responsive, supporto device */
@import 'responsive';
@import 'accessibility';

/* Debug, patch e override */
@import 'overrides';
