/* Classi generate: spazi */

@each $module in $modules {
  $i: index($modules, $module);
  .mb#{$i} { margin-bottom: #{$module}!important; }
  .mt#{$i} { margin-top: #{$module}!important; }
  .ml#{$i} { margin-left: #{$module}!important; }
  .mr#{$i} { margin-right: #{$module}!important; }
  .pb#{$i} { padding-bottom: #{$module}!important; }
  .pt#{$i} { padding-top: #{$module}!important; }
  .pl#{$i} { padding-left: #{$module}!important; }
  .pr#{$i} { padding-right: #{$module}!important; }
}

.mb0 { margin-bottom: 0!important; }
.mt0 { margin-top: 0!important; }
.ml0 { margin-left: 0!important; }
.mr0 { margin-right: 0!important; }
.pb0 { padding-bottom: 0!important; }
.pt0 { padding-top: 0!important; }
.pl0 { padding-left: 0!important; }
.pr0 { padding-right: 0!important; }


/* Classi generate: font */

.t0{font-size: $font0;}
.t1{font-size: $font1;}
.t2{font-size: $font2 !important;}
.t3{font-size: $font3;}
.t4{font-size: $font4;}
.t5{font-size: $font5;}

@each $weight in $fontweights {
  .t#{$weight} { 
    font-weight: #{$weight};
  }
}

/* Reset */

h1, h2, h3, h4, h5, h6, p, ul, li, ol {
  color: $color_greyscale_darker;
  margin-bottom: 0;
}


/* Icone */

.icon_s{
  font-size: .75rem
}

.icon_m{
  font-size: 1.5rem
}

.icon_l{
  font-size: 2rem
}


/* View containers */

.ts_viewcontainer {
  display: block;
  height: calc(100vh - 6rem);
  overflow-y: auto;
}

.ts_view .ts_viewcontent {
  display: block;

  .ts_viewcontent_margins {
    margin: 4rem 6rem;
  }
}

.ts_view_content {
  display: block;
  padding: $m6;
  overflow-y: auto;
}

.ts_data_table {
  position: relative;
  display: block;
  height: auto;
  overflow-y: hidden;
}

.ts_devexpress_table {
  overflow: visible !important;
  margin-top: 1rem;
}

/* Struttura */

.root {
  width: 100%;
  overflow-x: hidden;
}

.ts_sidebar {
  transition: $speed_fast;
  overflow-x: hidden;
  background-color: #547279;
  z-index: 2;
  height: 100vh;
}

.ts_body {
  height: 100vh;
  overflow-x: hidden;
  overflow-y: scroll;
  background-color: $color_greyscale_light;
  transition: all 500ms 0ms cubic-bezier(.86, 0, .07, 1);
}

.ts_content_row{
  height: 100vh;
  overflow: hidden;
}

.ts_content_header {
  align-items: center;
  display: flex;
  background-color: white;
  z-index: 2;
  position: relative;
  height: $navbar_height;
  padding: 0 4rem 0 4rem;

  &.active {
    box-shadow: 5px 5px 10px rgba(0, 0, 0, .05);
  }
}

.ts_sidebar_header {
  transition: $speed_fast;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: visible;
  padding: 6rem 0 6rem 0;
}

.ts_sidebar_header img {
  width: 17rem;
  object-fit: contain;
  transition: $speed_fast;
  cursor: pointer;
}

.ts_sidebar_expanded {
  max-width: $sidebarWidth !important;
}

.ts_sidebar_toggle i {
  transition: $speed_fast;
}

.ts_sidebar_minimized {
  max-width: 6rem !important;
  overflow-x: hidden;

  img{
    width: 4rem;
    height: 4rem;;
  }

}

.dx-tabpanel {
  background-color: white;
}
.dx-tabpanel > .dx-tabpanel-tabs .dx-tab.dx-tab-selected {
  background-color: white;
  font-weight: bold;
}
.dx-tabpanel > .dx-tabpanel-tabs .dx-tab .dx-tab-text .dx-icon{
  margin-bottom: 6.5px;
}

.box-info {
  border: 1px solid #EEEEEE;
  height: 100%;

  .box-info-header {
    background-color: #EEEEEE;
    display: flex;
    padding: 1.5rem;
  }

  .box-info-body {
    padding: 1.5rem;
  }
}

.p1-6 {
  padding: 1.6rem;
}

.m1-6 {
  margin: 1.6rem;
}

.statistiche_view {
  font-family: "Helvetica Neue", "Segoe UI", helvetica, verdana, sans-serif;

  .chart-wrapper {
    margin-bottom: 2rem;
    border: 1px solid white;
    background-color: white;
    padding: 5rem;

    .chart-info {
      margin-bottom: 2rem;
      .chart-title {
        display: flex;
      }
      .chart-select {
        margin-top: 1rem;
      }
    }

  }
}

/* Tablet e smartphone */

@media screen and (max-width: 1024px) {
  .chart-info {
    margin-bottom: 2rem;
  }

  .statistiche_view {
    .chart-wrapper {
      padding: 2rem 1rem;
    }
  }
}

@media screen and (max-width: 992px) {
  .logo-mobile{
    max-width: 120px !important;
    height: auto;
  }
  .ts_view_content {
    padding: 2rem;
  }

  .ts_content_header{
    position: fixed;
    width: 100%;
    padding: 1rem 2rem;
    height: 8rem;
  }

  .ts_content_body {
    &:not(._disable-navigation) {
      padding-top: 8rem;
    }
  }
}

/* Solo Tablet */

@media screen and (max-width: 992px) and (min-width: 768px) {

}

/* Smartphone */

@media screen and (max-width: 767px) {
  .ts_content_body {
    margin-bottom: 10rem;
  }
}