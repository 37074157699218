/** Modals **/

.ts_modal_wrap {
	z-index: 99999;
	position: fixed;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
}

.ts_modal_back {
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	background-color: rgba(0, 0, 0, .7);
}

.ts_modal_frame {
	position: relative;
	width: auto;
	height: auto;
	background-color: white;
	box-shadow: 0 0 10px $color_greyscale_darker;
	border-radius: $radius_small;
}

.ts_modal_frame_fullscreen {
	position: relative;
	width: 80%;
	height: 95%;
	//height: auto;
	background-color: white;
	box-shadow: 0 0 10px $color_greyscale_darker;
	border-radius: $radius_small;
}

.ts_modal_frame_video {
	position: relative;
	width: auto;
	max-width: 70%;
	height: auto;
	background-color: white;
	box-shadow: 0 0 10px $color_greyscale_darker;
	border-radius: $radius_small;
}

.ts_modal_content {
	position: relative;
	width: 100%;
	height: 100%;

	.buttons_wrapper {
		@extend %buttons_wrapper;
	}

	@extend %outlined_buttons;

	.max_chars_limit {
		display: inline-block;
		margin-left : 1rem;
		color: $_accent_red;
	}
}

.ts_modal_content_asset {
	position: relative;
	width: 100%;
	height: 100%;
	padding: 1rem;

	.modal_asset_header {
		margin-bottom: 1rem;

		button {
			background-color: transparent;
			border: none;

			i, i.fa-time:before {
				display: block;
				font-size: 2rem;
				content: "\f00d" !important;
			}
		}
	}

	.asset_pdf {
		//overflow: auto;
		//height: 750px;
		height: 100%;

		.rpv-default-layout__toolbar {
			height: 3.5rem;
		}

		.rpv-core__inner-pages.rpv-core__inner-pages--vertical {
			padding-top: 2rem !important;
		}

		.close-btn {
			border: none;
			margin-right: 7px;
			i.fas:before {
				display: block;
				content: "\f00d" !important;
				//font-weight: 400 !important;
			}
		}
	}

	._close-btn {
		border: none;
		margin-right: 7px;
		i.fas:before {
			display: block;
			content: "\f00d" !important;
			//font-weight: 400 !important;
		}
	}

	.image_asset {
		img {
			width: 100%;
			max-width: 65vh;
			height: auto;
		}
	}

	video {
		width: 100%;
		height: auto;
	}
}

.ts_modal_content_scroll {
	overflow: auto;
}

.ts_modal_content_small {
	padding: $m5;
	width: 40rem;
	max-width: calc(100vw - 3rem);
	height: auto;
	max-height: calc(100vh - 3rem);
	position: relative;
	z-index: 1;
}

.ts_modal_content_medium {
	padding: $m5;
	width: 60rem;
	max-width: calc(100vw - 3rem);
	height: auto;
	max-height: calc(100vh - 3rem);
	position: relative;
	z-index: 1;
}

.ts_modal_content_large {
	padding: $m5;
	width: 70rem;
	max-width: calc(100vw - 3rem);
	height: auto;
	max-height: calc(100vh - 3rem);
	position: relative;
	z-index: 1;
	&.extra_width {
		width: 80rem;
	}
}

.ts_modal_content_large2 {
	padding: 3.2rem 0.5rem 0.2rem 0.5rem;
	//width: 70vw;
	max-width: calc(100vw - 3rem);
	height: auto;
	//min-height: calc(50vw - 3rem);
	max-height: calc(100vh - 3rem);
	position: relative;
	z-index: 1;
}

.ts_modal_content_xlarge {
	padding: $m5;
	max-width: calc(100vw - 3rem);
	height: auto;
	max-height: calc(100vh - 3rem);
	position: relative;
	z-index: 1;
}

//css per modale fatture
.pdf_content {
	overflow: auto;
	padding: 4rem 1rem 1rem 1rem;
	width: 100vh;
	height: 90vh;
	position: relative;
	z-index: 1;
}

.ts_modal_close {
	position: absolute;
	right: 0;
	top: 0;
	padding: 1rem;
	cursor: pointer;
	z-index: 2;
	width: 3.4rem;
	height: 3.4rem;

	i.fas:before {
		display: block;
		font-size: 2rem;
		content: "\f00d" !important;
	}
}

.ts_modal_download {
	position: absolute;
	right: 0;
	top: 0;
	padding: 1rem;
	cursor: pointer;
	z-index: 2;
	width: 3.4rem;
	height: 3.4rem;

	i.fas:before {
		display: block;
		font-size: 1.8rem;
		color: black;
	}
}

.modal_success_icon {
	text-align: center;
	margin-bottom: $m4;

	i {
		display: block;
		font-size: 10rem;
		color: $color_secondary_medium;
	}
}

.modal_fail_icon {
	text-align: center;
	margin-bottom: $m4;

	i {
		display: block;
		font-size: 10rem;
		color: $color_warning_medium;
	}
}

.modal-img {
	max-width: 100%;
}

.slider {
	max-height: 70vh;
}

.box-img {
	height: 15vh;
	//width: 20vh;
	//border-radius: 0.375rem;
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center center;
}

.box-img:hover {
	opacity: 0.6;
}

.btn-over-img {
	color: white;
	font-size: 3rem;
}

.modal-update {
	p {
		width: 100% !important;
		display: block;
		font-size: 14px;
		margin-bottom: 20px;
	}

	img {
		text-align: center;
		margin: 10px 0;
	}
}

.react-pdf__Page {
	z-index: -1
}

.OLD_modal-pdf {
	//position: relative;
	overflow-x: hidden;
	//overflow: auto;
	z-index: 99999;

	//padding: 0 1rem;
	width: auto;
	max-width: calc(100vw - 3rem);
	height: auto;
	max-height: calc(100vh - 3rem);

	.modal-header {
		background-color: black;
		color: white;
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		justify-content: end;
	}

	.modal-body {
		padding: 0;

		.react-pdf__Document {

		}

		.react-pdf__Page {
			//border: solid 1px #EEEEEE;
			//margin-bottom: 1rem;
		}

		canvas {
			height: 80vh !important;
			width: 70vw !important;
		}
	}

	.modal-footer {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		justify-content: space-between;
		background-color: black;
		color: white;
	}

	.modal-btn {
		padding: 0.5rem 1.3rem;
		//background-color: #2A2A2A;
		background-color: black;
		color: white;
		border: none;
		font-size: 1.5rem;

		i {
			margin-right: 1rem;
		}

		&:hover {
			opacity: 0.8;
		}
	}
}

@media screen and (max-width: 992px) {
	.pdf_content {
		width: 43vh;
		height: 67vh;
	}

	.ts_modal_content_large2 {
		width: 90vw;
	}

	.modal-pdf {
		overflow: hidden;

		canvas {
			height: 200px !important;
			width: 360px !important;
		}
	}
}

@media screen and (max-width: 768px) {
	position: relative;
	z-index: 99999;
}

@media screen and (max-width: 400px) {
	.ts_modal_content_asset {
		//padding: 1rem 0.5rem 0.5rem 0.5rem;

		//width: auto;
		//min-width: 30rem;
		//max-width: calc(100vw - 10rem);

		//height: auto;
		//min-height: 30rem;
		//max-height: calc(100vh - 10rem);

		position: relative;
		z-index: 99999;
	}

	.ts_modal_frame_fullscreen {
		width: 95%;
	}

	.ts_modal_frame {
		width: 95%;
	}
}
