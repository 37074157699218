.btn{
  font-size: 1.4rem;
  transition: 0.3s;
  font-weight: 700;
  align-items: center;
  outline: none !important;
  box-shadow: none !important;

  &:hover{
    background: transparent !important;
  }

  &.btn-primary{
    background: $color_greyscale_dark !important;
    border-color: $color_greyscale_dark !important;
  }

  &.btn-danger{
    background: $color_warning_medium !important;
    border-color: $color_warning_medium !important;
  }
}

.rounded{
  overflow: hidden;
}

.dx-widget{
  font-family: $appFont;
}

.slider-slide > img{
  margin: auto !important;
}