.ts_input1 {
    border: none;
    border-bottom: solid 2px $color_greyscale_dark;
    height: $m6;
    //padding: 0 $m2;
    font-size: $font2;
    width: 100%;
    background-color: transparent;
}

.ts_textarea1 {
    border: solid 2px $color_greyscale_dark;
    min-height: 16rem;
    padding: $m2;
    font-size: $font3;
    width: 100%;
}

.ts_select1 {
    font-size: $font2;
    height: $m6;
    width: 100%;
    transition: $speed_fast;
    border: none;
    border-bottom: solid 2px $color_greyscale_dark !important;
    background-color: transparent;
    color: $color_greyscale_dark;
    padding: 0 !important;

    &:focus {
        outline: none;
    }
}

.ts_select1_wrap {
    width: 100%;
    position: relative;
    height: $m6;

    /*&:before {
        content: "\f078";
        font-weight: 900;
        display: block;
        font-family: 'Font Awesome 5 Free';
        position: absolute;
        top: 50%;
        right: 1.2rem;
        transform: translateY(-50%);
        color: $color_greyscale_darker;
        font-size: $font2;
        pointer-events: none;
    }*/
}

.ts_button1 {
    height: $m6;
    padding: 0 $m4;
    font-size: $font2;
    color: $color_greyscale_white;
    background-color: $color_greyscale_black;
    border: solid 2px $color_greyscale_black;
    transition: $speed_fast;
    font-weight: 700;
    display: inline-flex;
    align-items: center;
    cursor: pointer;

    &:hover {
        background-color: white;
        color: $color_greyscale_black;

    }

    i {
        font-size: 1.7rem;
        margin-right: $m2;
    }

}

.ts_button2 {
    height: $m6;
    padding: 0 $m4;
    font-size: $font2;
    color: $color_greyscale_white;
    background-color: $color_greyscale_black;
    border: solid 2px $color_greyscale_black;
    font-weight: 700;
    display: inline-flex;
    align-items: center;
}

.ts_button3 {
    height: $m6;
    padding: 0 $m4;
    font-size: $font2;
    color: $color_greyscale_white;
    background-color: $color_success_medium;
    border: solid 2px $color_success_medium;
    font-weight: 700;
    display: inline-flex;
    align-items: center;
}

.ts_button4 {
    background-color: transparent;
    color: black;
    border: none;
}

.ts_buttoninline1 {
    height: $m6;
    padding: 0 $m4;
    font-size: $font2;
    color: $color_greyscale_dark;
    border: solid 2px transparent;
    transition: $speed_fast;
    font-weight: 400;
    display: inline-flex;
    align-items: center;
    cursor: pointer;

    i {
        font-size: 1.7rem;
        margin-right: $m2;
    }
}

.ts_link1 {
    font-size: $font2;
    text-decoration: underline;
    cursor: pointer;
}

.close_section {
    display: inline-block;
    margin-left: $m3;
    cursor: $color_greyscale_dark;
}

// variazioni
.btn_color1 {
    background-color: rgb(217,119,6);
    border-color: rgb(217,119,6);

    &:hover {
        border-color: $color_greyscale_dark;
    }
}

.btn_color2 {
    color: white;
    background-color: #1770b8;
    border-color: #1770b8;

    &:hover {
        border-color: $color_greyscale_dark;
    }
}

.btn_color3 {
    color: white;
    background-color: #cb3f4b;
    border-color: #cb3f4b;

    &:hover {
        border-color: $color_greyscale_dark;
    }
}

.btn_color4 {
    color: white;
    background-color: $color_success_medium;;
    border-color: $color_success_medium;;

    &:hover {
        border-color: $color_greyscale_dark;
    }
}

.blocked-order {
    border: #EE3E4D;
    height: $m4;
    width: $m4;
    background-color: #EE3E4D;
}

.suborders {
    border: #94C3FF;
    height: $m4;
    width: $m4;
    background-color: #94C3FF;
}

.remove-file-btn {
    /*display: flex;
    justify-content: center;
    align-items: center;
    position: relative;*/
    cursor: pointer;

    i {
        font-size: 4rem;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 5rem;
        height: 5rem;
        color: $color_greyscale_dark;
    }
}

@media screen and (max-width: 767px) {
    .ts_button1 {
        width: 100%;
    }
}