.tesy_view_home{
    .ts_view_content{
        padding-bottom: 0;
    }
}

.ts_dashboard_row {
    margin-bottom: #{"max(3vh,20px)"};
    &.academy_wrp {
        padding: 0;
    }
}

//old
.tutorial_widget {
    overflow: hidden;
    position: relative;
    cursor: pointer;
    min-height: 200px;
    height: 25.5vh;

    i {
        position: absolute;
        z-index: 1;
        top: 1rem;
        right: 1rem;
        color: white;
        font-size: 12rem;
        opacity: 0.5;
    }

    .ts_dashboard_widget_title {
        background-color: #E6B35B;
        height: 100%;

        &.color1 {
            background-color: #E6B35B;
        }

        &.color2 {
            background-color: #8E9C8E;
        }

        &.color3 {
            background-color: #4E8D6E;
        }

        &.color4 {
            background-color: #BCBC90;
        }

        &.color5 {
            background-color: #71A3B8;
        }

        &.color6 {
            background-color: #89896F;
        }

        &.color7 {
            background-color: #D5D596;
        }
    }
}

.ts_dashboard_widget_cta {

    position: absolute;
    width: 100%;
    bottom: 0;
    left: 0;

    .ts_dashboard_widget_cta_title{
        color: white;
        font-size: $font4;
        padding: $m2 $m3 $m2 $m3;
        line-height: 1;
    }

    .ts_dashboard_widget_cta_btn{
        background-color: $color_greyscale_dark;
        color: $color_greyscale_white;
        font-size: $font2;
        text-transform: uppercase;
        padding: $m2 $m3 $m2 $m3;
        font-weight: 700;
    }
}

.ts_dashboard_widget2 {
    overflow: hidden;
    position: relative;
    cursor: pointer;
    //height: 43rem;
    padding: $m3;
    height: 54vh;
    min-height: 482px;
    background-color: white;

    /*&.color1 {
        background-color: #E6B35B;
    }

    &.color2 {
        background-color: #8E9C8E;
    }

    &.color3 {
        background-color: #4E8D6E;
    }

    &.color4 {
        background-color: #BCBC90;
    }

    &.color5 {
        background-color: #71A3B8;
    }

    &.color6 {
        background-color: #89896F;
    }

    &.color7 {
        background-color: #D5D596;
    }

    &.color8 {
        background-color: #d9d9d9;
    }*/
}

.dashboard_widget {
    position: relative;
    cursor: pointer;
    min-height: 200px;
    /*height: 25.5vh;*/


    .dashboard_widget_body {
        height: 20.5vh;
        min-height: 180px;

        .dashboard_widget_body_icon {
            height: 100%;

            i {
                position: absolute;
                z-index: 1;
                color: white;
                font-size: 12rem;
                opacity: 0.5;
            }
        }
    }
    .dashboard_widget_footer {
        height: 5vh;
        min-height: 48px;
        background-color: $color_greyscale_black;
        color: $color_greyscale_white;
        font-size: $font2;
        text-transform: uppercase;
        padding: $m2 $m3 $m2 $m3;
        font-weight: 700;

        .dashboard_widget_btn {
            background-color: white;
            color: black;
            height: 3rem;
            width: 4rem;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
}

.ts_dashboard_widget_stats {
    flex: 1;
    position: relative;
}

.ts_dashboard_chart_title {
    color: white;
    font-weight: 300;
    font-size: 6rem;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    letter-spacing: -.05em;
}

.ts_dashboard_chart {
    overflow: hidden;
    position: relative;
    /*left: 0;
    right: 0;
    top: 0;*/
    width: 100%;
    height: auto;

    #pie {
        height: auto;
        width: 100%;;
    }
}

.ts_dashboard_widget_icon {
    background-color: #5EA17F;
    color: white;
    width: 4rem;
    height: 4rem;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 2rem;
    margin-right: $m2;

    &.active {
        background-color: #DDCA99;
    }

}

.ts_dashboard_widget_stats_title {
    color: white;
    font-size: $font1;
    font-weight: 700;
    margin: 0 0 .1rem 0;
    opacity: .8;
}


.ts_dashboard_widget_stats_subtitle {
    color: white;
    font-size: $font1;
    font-weight: 400;
    opacity: .6;
    margin: 0;
}

.ts_dashboard_widget_title2 {
    color: white;
    font-size: $font4;
}

.ts_dashboard_widget2_layout {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}

.title-chart {
    text-decoration: underline;
    text-transform: uppercase;
    font-size: 2.5rem;
    text-align: center;
    margin-bottom: 4rem;
}

.dashboard_chart {

    .dxc-title {
        text-transform: uppercase;
        text {
            font-size: 2.1rem !important;
        }
    }
    .dashboard_chart_layout {
        position: relative;
        height: 54vh;
        min-height: 420px;
        padding: $m3;
        background-color: #d9d9d9;
        cursor: pointer;
    }

    .dashboard_chart_footer {
        background-color: $color_greyscale_dark;
        color: $color_greyscale_white;
        font-size: $font2;
        text-transform: uppercase;
        padding: $m2 $m3 $m2 $m3;
        font-weight: 700;
    }
}

.topnordini_box {
    overflow: hidden;
    position: relative;
    min-height: 200px;
    height: 25.5vh;
    background-color: #915a66;
    padding: $m2 $m3 $m2 $m3;

    .dx-datagrid-headers {
        background-color: #ac7180;
        border-bottom: none;

        .dx-datagrid-table {
            .dx-row {
                td {
                    .dx-datagrid-text-content {
                        color: white;
                    }
                }
            }
        }
    }

    .topnordini_title {
        color: white;
        font-size: $font3;
        line-height: 1;
        padding-top: $m2;
    }

    .topnordini_table {
        padding-top: $m2;

        .dx-datagrid-headers {
            color: white !important;
        }
        .dx-datagrid-content {
            color: white;
        }
    }
}

@media screen and (max-width: 767px) {
    .ts_dashboard_row {
        margin-bottom: $m4;
    }

    .title-chart {
        margin-bottom: 0;
        font-size: 2rem;
    }

}
