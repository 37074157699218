.ts_grid_pagination {
  height: 7rem;
  padding: 2rem;
  margin-top: 3rem;
  margin-right: 0px;

  .ts_scrolltopbutton {
    position: fixed;
    bottom: 2rem;
    right: 2rem;
    z-index: 10;
    opacity: 0;

    &.active {
      opacity: 1;
    }
  }

  .ts_grid_pagination_filler {
    min-width: 3rem;
    padding-top: .5rem;
    padding-bottom: .5rem;
    font-size: $font1;
    text-align: center;
    background-color: transparent;
    margin-left: .5rem;
  }

  .ts_grid_pagination_item {
    min-width: 3rem;
    padding-top: .5rem;
    padding-bottom: .5rem;
    font-size: $font1;
    cursor: pointer;
    text-align: center;
    background-color: white;
    margin-left: .5rem;


    &.active {
      background-color: $color_greyscale_dark;
      color: white;
    }
  }
}