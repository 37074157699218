@import "icons";

.page-standalone {
	position: relative;
	min-height: 100vh;
	background: $_accent_white;

	.company_logo {
		max-width: 250px;
		margin-top: 3rem;
		margin-bottom: 2rem;
	}

	.public-lang-switcher-wrapper {
		position: absolute;
		top: 2.5rem;
		right: 2.5rem;

		.lang-switcher .lang-selected div {
			padding: 1.5rem 0;
			text-align: center;
			color: black;
			background-color: #f0f0f0;
		}
	}

	.error-msg {
		color: $_accent_red;
	}

	.message_page {
		margin-top: 5rem;
		margin-bottom: 15rem;
	}

	.limited-content-container {
		width: 100%;
		max-width: 768px;
		padding: 0 1rem 2.5rem;
	}

	&, div, h1, h2, h3, h4, h5, h6, p, ul, li, a, input, button {
		font-family: $appStandalonePagesFont;
		font-weight: 700;
		color: $color_greyscale_dark;
	}

	> * {
		margin-bottom: 1rem;
		line-height: 1;
	}

	.text {
		font-size: 2.4rem;
		letter-spacing: 0.1rem;
		&.upper {
			text-transform : uppercase;
		}
	}

	.title {
		font-size: 4.8rem;
		letter-spacing: 0.1rem;
		text-transform: uppercase;
		.tesy_view_otp-aap & {
			font-size: 4rem;
		}

		&_main {
			font-size: 6.4rem;
			letter-spacing: 0.2rem;
			.tesy_view_otp-aap & {
				font-size: 6rem;
			}
		}

		&_sub {
			font-size: 2.8rem;
			i.fas {
				margin-bottom: 20px;
				font-size: 50px;
			}
			span a {
				text-decoration: underline;
			}
			small {
				display: block;
				margin-top: 3rem;
				text-transform: uppercase;
				font-size: 1.2rem;
				letter-spacing: 1px;
				opacity: 0.7;
			}
		}
	}

	.buttons_wrapper {
		@extend %buttons_wrapper;
		.buttons_inner {
			display         : flex;
			justify-content : center;
			@media screen and (max-width : 576px) {
				flex-direction : column;
				align-items    : center;
			}
		}
	}

	@extend %outlined_buttons;

	.margin_bottom {
		&_large {
			margin-bottom: 4rem;
		}
		&_narrow {
			margin-bottom: 2rem;
		}
	}

	.otp_input {
		margin-bottom: 3rem;
		input {
			width: 75px !important;
			height: 75px;
			//border-radius: 10px;
			border: none;
			font-size: 40px;
			box-shadow: inset -2px 2px 10px rgba(#000, .2);
			margin: 0 13px;
			color: #000;

			@media screen and (max-width: 767px) {
				width: 50px !important;
				height: 50px;
				margin: 0 7px;
				font-size: 30px;
			}
		}
	}

	.otp_accept {
		@include clearfix;
		display       : flex;
		align-items   : flex-start;

		margin-bottom: 3rem;

		label {
			max-width: 95%;
			margin-left: 5px;
			text-align: left;
			font-size: 14px;
			font-weight: normal;
			cursor: pointer;
		}
	}

	#footer {
		display: flex;
		margin-top: 50px;
		margin-bottom: 20px;
		justify-content: space-between;
		div, p, ul, li, a {
			color:#bebebe;
		}

		ul {
			padding-left: 0;
			list-style: none;

			&:nth-child(1) {
				text-align: left;
			}

			&:nth-child(2) {
				text-align: center;
			}

			&:nth-child(3) {
				text-align: right;
			}

			li {
				display: inline-block;
				text-align: left;
				font-size: 10px;
				font-weight: normal;
				text-transform: uppercase;

				&.separtor {
					margin-left: 6px;
					margin-right: 6px;
				}

				a:hover {
					color: $color_greyscale_black;
				}
			}
		}
	}
}

.company_info_wrapper {
	font-size: 14px;
	line-height: 22px;

	h3, p {
		margin-bottom: 20px;
	}

	h3 {
		font-size: 30px;
	}
}

.tesy_view_otp-co {
	padding-left: 0 !important;
}

@media screen and (max-width: 991px) {
	.page-standalone {
		.company_logo {
			max-width: 20rem;
			margin-top: 2rem !important;
		}

		.margin_bottom_large {
			margin-bottom: 2rem;
		}

		.margin_extended_mobile {
			margin-bottom: 3rem;
		}

		.text {
			font-size: 1.6rem;
			line-height: 1.2;
			.tesy_view_otp-aap & {
				font-size: 2rem;
				letter-spacing: 0.1rem;
			}
		}

		.title {
			font-size: 3.2rem;
			line-height: 1;

			&_main {
				font-size: 5rem;
				margin: 2rem 0;
			}
		}

		.outlined {
			&_card {
				padding: 2rem 3rem;
			}

			&_cta {
				height: 45px;
				font-size: 2rem;
			}
		}
	}
}

@media screen and (max-width: 767px) {
	.tesy_view_otp-co {
		.ts_content_body {
			margin-bottom: 0;
		}
	}

	.page-standalone #footer {
		flex-direction: column;
		ul {
			text-align: center !important;
			margin-bottom: 30px;
		}
	}
}
