/** Tooltips **/


.ts_tooltip_content {
  color: white;
  font-size: 1rem;

  div {
    margin-bottom: .6rem;

    &:last-child {
      margin-bottom: 0;
    }
  }

  h3 {
    color: white;
    font-size: 1rem;
    font-weight: 700;
    font-size: 1.2rem;
    margin-bottom: .1rem;
  }
}

.ts_tooltip_hover {
  cursor: pointer;
}

.rc-tooltip {
  pointer-events: none;
}